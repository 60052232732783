<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item style="cursor: pointer">
          <span @click="onBack">
            经销商管理
          </span>
        </a-breadcrumb-item>
        <a-breadcrumb-item>绑定经销商</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <a-form :form="form" class="advanced-search-form">
      <div class="info-container">
        <div class="form-title">
          基本信息
        </div>
        <a-row>
          <a-col :span="8">
            <a-form-item label="统一社会信用代码">
              <a-modal
                title=""
                :visible="visible"
                :confirm-loading="confirmLoading"
                @ok="handleOk"
                @cancel="handleCancel"
              >
                该统一社会信用代码没有绑定
              </a-modal>
              <a-input
                v-decorator="[
                  'orgCode',
                  {
                    rules: [
                      { required: true, message: '请输入统一社会信用代码' }
                    ]
                  }
                ]"
                placeholder="请输入统一社会信用代码"
                :max-length="18"
                @input="onOrgCodeChange"
                @blur="onSearch"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="经销商编号">
              <a-input v-decorator="['dealerCode']" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="机构名称">
              <a-input
                v-decorator="[
                  'dealerName',
                  {
                    rules: [{ required: true, message: '请输入机构名称' }]
                  }
                ]"
                :disabled="isExist"
                placeholder="请输入机构名称"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="法人姓名">
              <a-input
                v-decorator="[
                  'legalPerName',
                  {
                    rules: [{ required: true, message: '请输入法人姓名' }]
                  }
                ]"
                :disabled="isExist"
                placeholder="请输入法人姓名"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="经销商类型">
              <a-select
                v-decorator="[
                  'dealerType',
                  { rules: [{ required: true, message: '请选择经销商类型' }] }
                ]"
                placeholder="请选择经销商类型"
                allow-clear
                @change="onChangeDealerType"
              >
                <a-select-option
                  v-for="(item, index) in typeList"
                  :key="index"
                  :value="item.venuType"
                >
                  {{ item.venuType }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="经销商地址">
              <a-cascader
                v-model="dealAreaCode"
                :options="areaTreeData"
                :field-names="areaTreeNames"
                placeholder="请选择区域"
                :disabled="isExistArea"
                @change="onChangeDealer"
              />
              <a-input
                v-decorator="['orgDetailAddress']"
                :disabled="isExistArea"
                placeholder="请输入详细地址"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </div>
      <div class="tel-container">
        <div class="form-title">{{ dealerType }}联系人信息</div>
        <a-row>
          <a-col :span="8">
            <a-form-item label="联系人姓名">
              <a-input
                v-decorator="[
                  'personName',
                  {
                    rules: [{ required: true, message: '请输入联系人姓名' }]
                  }
                ]"
                placeholder="请输入联系人姓名"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="联系人电话">
              <a-input
                v-decorator="[
                  'personTel',
                  {
                    rules: [
                      { required: true, message: '请输入联系人电话' },
                      { validator: phoneCheck.bind(this) }
                    ]
                  }
                ]"
                placeholder="请输入联系人电话"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="联系地址">
              <a-cascader
                v-model="perAreaCode"
                :options="areaTreeData"
                :field-names="areaTreeNames"
                placeholder="请选择区域"
                @change="onChangePer"
              />
              <a-input
                v-decorator="[
                  'perDetailAddress',
                  {
                    rules: [{ required: true, message: '请输入联系地址' }]
                  }
                ]"
                placeholder="请输入详细地址"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </div>
      <div class="examine-container">
        <div class="button-container">
          <a-button type="primary" :disabled="!isExist" @click="onAdd">
            完成
          </a-button>
        </div>
      </div>
    </a-form>
  </a-layout-content>
</template>

<script>
import { addBindDealer, loadArea, loadDetail } from "@/services/DealerService";

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      areaTreeData: [],
      areaTreeNames: {
        label: "areaName",
        value: "areaCode",
        children: "subAreas"
      },
      dealAreaCode: [],
      perAreaCode: [],
      isExist: false,
      typeList: [],
      visible: false,
      confirmLoading: false,
      addressList: [],
      isExistArea: false,
      venuId: "",
      dealerType: ""
    };
  },
  mounted() {
    this.onLoadArea();
  },
  methods: {
    onOrgCodeChange(e) {
      this.form.setFieldsValue({
        orgCode: e.target.value.replace(" ", "").toUpperCase()
      });
    },
    handleCancel() {
      this.visible = false;
    },
    handleOk() {
      this.visible = false;
    },
    onFoundDealer(resp) {
      const data = resp.data.data;
      if (data.dealerId) {
        this.form.setFieldsValue({
          dealerName: data.dealerName,
          orgDetailAddress: data.orgDetailAddress,
          legalPerName: data.legalPersonName ? data.legalPersonName : ""
        });
        this.dealAreaCode.push(data.orgProvinceCd);
        this.dealAreaCode.push(data.orgCityCd);
        this.dealAreaCode.push(data.orgCountyCd);
        const venuList = data.venuList;
        this.typeList = [];
        if (venuList.length > 0) {
          venuList.forEach(item => {
            this.typeList.push(item);
          });
        }
        this.isExist = true;
        this.isExistArea = true;
      } else {
        this.isExist = false;
        this.isExistArea = false;
        this.typeList = data.typeList;
        this.form.setFieldsValue({
          dealerName: "",
          orgDetailAddress: ""
        });
        this.dealAreaCode = [];
      }
    },
    onDealerNotFound() {
      this.isExist = false;
      this.isExistArea = false;
      this.typeList = [];
      this.visible = true;
      this.form.setFieldsValue({
        dealerName: "",
        orgDetailAddress: "",
        legalPerName: ""
      });
      this.dealAreaCode = [];
    },
    onSearch() {
      const param = this.form.getFieldsValue().orgCode;
      loadDetail(param).then(resp => {
        if (resp.data.code === "SUCCESS") {
          if (resp.data.data == null) {
            this.onDealerNotFound();
          } else {
            this.onFoundDealer(resp);
          }
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    onLoadArea() {
      this.areaTreeData = [];
      loadArea().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.areaTreeData = resp.data.data;
        } else {
          console.log(resp.data.errorMsg);
        }
      });
    },
    onAdd() {
      this.$store.state.isModify = false;
      this.form.validateFields((error, value) => {
        if (!error) {
          if (this.dealAreaCode.length < 3) {
            this.$message.error("请选择经销商地址区域信息");
            return;
          }
          if (this.perAreaCode.length < 3) {
            this.$message.error("请选择经销商地址区域信息");
            return;
          }
          const params = {
            venuId: this.venuId,
            personName: value.personName,
            personTel: value.personTel,
            provinceCode: this.perAreaCode[0],
            cityCode: this.perAreaCode[1],
            countyCode: this.perAreaCode[2],
            detailAddress: value.perDetailAddress,
            dealerCode: value.dealerCode ? value.dealerCode : null
          };
          const factoryId = localStorage.getItem("factoryId");
          addBindDealer(factoryId, params).then(resp => {
            if (resp.data.code === "SUCCESS") {
              this.$message.success("绑定成功");
              this.onBack();
            }
          });
        }
      });
    },
    onBack() {
      this.$router.push({ name: "DealerManage" });
    },
    onChangeDealer(value) {
      this.dealAreaCode = value;
    },
    onChangePer(value) {
      this.perAreaCode = value;
    },
    onChangeDealerType(value) {
      this.dealerType = value;
      if (this.typeList.length > 0) {
        this.dealAreaCode = [];
        this.typeList.forEach(item => {
          if (item.venuType === value) {
            this.venuId = item.venuId;
            this.dealAreaCode.push(item.orgProvinceCd);
            this.dealAreaCode.push(item.orgCityCd);
            this.dealAreaCode.push(item.orgCountyCd);
            this.form.setFieldsValue({
              orgDetailAddress: item.orgDetailAddress
            });
            this.isExistArea = true;
          }
        });
      }
    },
    phoneCheck(rule, value, callbackFn) {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (value && !reg.test(value)) {
        callbackFn("手机号码格式不正确");
        return;
      }
      callbackFn();
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
  display: flex;
  justify-content: center;
}
.form-container {
  flex-direction: column;
  width: 60%;
  display: flex;
  align-items: center;
}
.form-title {
  /*font-size: 18px;*/
  font-weight: 600;
  margin-bottom: 20px;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 135px !important;
}
.examine-container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}
.button-container {
  width: 12%;
  display: flex;
  justify-content: space-around;
}
/deep/.ant-btn {
  border-radius: 5px;
}
.info-container {
  box-sizing: border-box;
  padding: 30px;
  background-color: white;
  margin: 10px;
}
.tel-container {
  box-sizing: border-box;
  padding: 30px;
  background-color: white;
  margin: 0 10px 60px 10px;
}
</style>
