var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-content',[_c('div',{staticClass:"nav"},[_c('a-breadcrumb',[_c('a-breadcrumb-item',{staticStyle:{"cursor":"pointer"}},[_c('span',{on:{"click":_vm.onBack}},[_vm._v(" 经销商管理 ")])]),_c('a-breadcrumb-item',[_vm._v("绑定经销商")])],1)],1),_c('a-form',{staticClass:"advanced-search-form",attrs:{"form":_vm.form}},[_c('div',{staticClass:"info-container"},[_c('div',{staticClass:"form-title"},[_vm._v(" 基本信息 ")]),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"统一社会信用代码"}},[_c('a-modal',{attrs:{"title":"","visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_vm._v(" 该统一社会信用代码没有绑定 ")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'orgCode',
                {
                  rules: [
                    { required: true, message: '请输入统一社会信用代码' }
                  ]
                }
              ]),expression:"[\n                'orgCode',\n                {\n                  rules: [\n                    { required: true, message: '请输入统一社会信用代码' }\n                  ]\n                }\n              ]"}],attrs:{"placeholder":"请输入统一社会信用代码","max-length":18},on:{"input":_vm.onOrgCodeChange,"blur":_vm.onSearch}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"经销商编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['dealerCode']),expression:"['dealerCode']"}]})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"机构名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'dealerName',
                {
                  rules: [{ required: true, message: '请输入机构名称' }]
                }
              ]),expression:"[\n                'dealerName',\n                {\n                  rules: [{ required: true, message: '请输入机构名称' }]\n                }\n              ]"}],attrs:{"disabled":_vm.isExist,"placeholder":"请输入机构名称"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"法人姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'legalPerName',
                {
                  rules: [{ required: true, message: '请输入法人姓名' }]
                }
              ]),expression:"[\n                'legalPerName',\n                {\n                  rules: [{ required: true, message: '请输入法人姓名' }]\n                }\n              ]"}],attrs:{"disabled":_vm.isExist,"placeholder":"请输入法人姓名"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"经销商类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'dealerType',
                { rules: [{ required: true, message: '请选择经销商类型' }] }
              ]),expression:"[\n                'dealerType',\n                { rules: [{ required: true, message: '请选择经销商类型' }] }\n              ]"}],attrs:{"placeholder":"请选择经销商类型","allow-clear":""},on:{"change":_vm.onChangeDealerType}},_vm._l((_vm.typeList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.venuType}},[_vm._v(" "+_vm._s(item.venuType)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"经销商地址"}},[_c('a-cascader',{attrs:{"options":_vm.areaTreeData,"field-names":_vm.areaTreeNames,"placeholder":"请选择区域","disabled":_vm.isExistArea},on:{"change":_vm.onChangeDealer},model:{value:(_vm.dealAreaCode),callback:function ($$v) {_vm.dealAreaCode=$$v},expression:"dealAreaCode"}}),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['orgDetailAddress']),expression:"['orgDetailAddress']"}],attrs:{"disabled":_vm.isExistArea,"placeholder":"请输入详细地址"}})],1)],1)],1)],1),_c('div',{staticClass:"tel-container"},[_c('div',{staticClass:"form-title"},[_vm._v(_vm._s(_vm.dealerType)+"联系人信息")]),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"联系人姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'personName',
                {
                  rules: [{ required: true, message: '请输入联系人姓名' }]
                }
              ]),expression:"[\n                'personName',\n                {\n                  rules: [{ required: true, message: '请输入联系人姓名' }]\n                }\n              ]"}],attrs:{"placeholder":"请输入联系人姓名"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"联系人电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'personTel',
                {
                  rules: [
                    { required: true, message: '请输入联系人电话' },
                    { validator: _vm.phoneCheck.bind(this) }
                  ]
                }
              ]),expression:"[\n                'personTel',\n                {\n                  rules: [\n                    { required: true, message: '请输入联系人电话' },\n                    { validator: phoneCheck.bind(this) }\n                  ]\n                }\n              ]"}],attrs:{"placeholder":"请输入联系人电话"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"联系地址"}},[_c('a-cascader',{attrs:{"options":_vm.areaTreeData,"field-names":_vm.areaTreeNames,"placeholder":"请选择区域"},on:{"change":_vm.onChangePer},model:{value:(_vm.perAreaCode),callback:function ($$v) {_vm.perAreaCode=$$v},expression:"perAreaCode"}}),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'perDetailAddress',
                {
                  rules: [{ required: true, message: '请输入联系地址' }]
                }
              ]),expression:"[\n                'perDetailAddress',\n                {\n                  rules: [{ required: true, message: '请输入联系地址' }]\n                }\n              ]"}],attrs:{"placeholder":"请输入详细地址"}})],1)],1)],1)],1),_c('div',{staticClass:"examine-container"},[_c('div',{staticClass:"button-container"},[_c('a-button',{attrs:{"type":"primary","disabled":!_vm.isExist},on:{"click":_vm.onAdd}},[_vm._v(" 完成 ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }